(function () {
  'use strict';

  angular
    .module('dashboard.discussions')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('dashboard.discussions', {
        url: '/discussions',
        templateUrl: 'dashboard/discussions/discussions.tpl.html',
        controller: 'DiscussionsCtrl',
        controllerAs: 'discussions',
        abstract: true
      });
  }
}());
